import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Small`}</h2>
    <p>{`Small shadows are mainly used on things that need to appear slightly elevated, like pricing cards or UI elements containing important information.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-shadow--small'
    }]} height="75" mdxType="StorybookEmbed" />
    <p>{`These types of shadows are typically applied to elements with borders, such as the `}<a parentName="p" {...{
        "href": "/components/box"
      }}>{`Box component`}</a>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-shadow--small-example'
    }]} height="300" mdxType="StorybookEmbed" />
    <h2>{`Medium`}</h2>
    <p>{`Medium box shadows are more diffused and slightly larger than small box shadows.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-shadow--medium'
    }]} height="75" mdxType="StorybookEmbed" />
    <p>{`Medium box shadows are typically used on editorialized content that needs to appear elevated. Most of the time, the elements using this level of shadow will be clickable.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-shadow--medium-example'
    }]} height="220" mdxType="StorybookEmbed" />
    <h2>{`Large`}</h2>
    <p>{`Large box shadows are very diffused and used sparingly in the product UI.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-shadow--large'
    }]} height="75" mdxType="StorybookEmbed" />
    <p>{`These shadows are used for marketing content, UI screenshots, and content that appears on top of other page elements.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-shadow--large-example'
    }]} height="375" mdxType="StorybookEmbed" />
    <h2>{`Extra large`}</h2>
    <p>{`Extra large box shadows are even more diffused.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-shadow--extra-large'
    }]} height="75" mdxType="StorybookEmbed" />
    <p>{`These shadows are used for marketing content and content that appears on top of other page elements.`}</p>
    <h2>{`Remove a box shadow`}</h2>
    <p>{`Additionally there is a `}<inlineCode parentName="p">{`box-shadow-none`}</inlineCode>{` class that removes `}<inlineCode parentName="p">{`box-shadow`}</inlineCode>{`:`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-shadow--none'
    }]} height="75" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      